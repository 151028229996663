import Header from '@components/Header';
import Container from '@components/ui/Container';
import Head from 'next/head';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import styled from 'styled-components';

import Full404 from './assets/404-full.svg';

const NotFoundContainer = styled(Container)`
  height: 100vh;
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: bottom / cover no-repeat url(${Full404.src});
  @media screen and (max-width: 1700px) {
    background-size: 100%;
  }
  @media screen and (max-width: 920px) {
    align-items: center;
  }
`;

const NotFoundMessage = styled(Container)`
  margin-bottom: 44px;
  @media screen and (min-width: 1500px) {
    margin: 0 auto;
    max-width: 1200px;
  }
`;

const NotFoundTitle = styled.h1`
  margin: 0 0 2px 0;
  font-family: 'Pragmatica';
  font-style: normal;
  font-weight: 700;
  font-size: 46px;
  line-height: 66px;
  letter-spacing: 0.1em;
  color: #ffffff;
  text-transform: uppercase;
  @media screen and (max-width: 520px) {
    font-size: 42px;
  }
`;

const NotFoundSubtitle = styled.p`
  margin: 0 0 56px;
  font-family: 'Pragmatica';
  font-weight: 400;
  font-size: 18px;
  line-height: 144%;
  letter-spacing: 0.04em;
  color: #ffffff;
  @media screen and (max-width: 920px) {
    font-size: 22px;
  }
  @media screen and (max-width: 520px) {
    font-size: 22px;
  }
`;

const NotFoundButton = styled.a`
  font-family: 'Pragmatica';
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
  letter-spacing: 0.04em;
  width: 100%;
  max-width: 300px;
  padding: 18px 46px;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  transition: 0.3s;
  text-align: center;
  @media screen and (max-width: 520px) {
    min-width: 100%;
    display: block;
    text-align: center;
  }
  &:hover {
    background: #ffffff;
    color: #343434;
  }
`;

export const getStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, [
      '404',
      'routes',
      'cookieConsent',
    ])),
  },
});

const NotFound = () => {
  const { t } = useTranslation('404');

  return (
    <>
      <Head>
        <title>{t('head_title')}</title>
        <meta name="description" content={t('head_description')} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <NotFoundContainer>
        <Header hideLinks color="black" />
        <NotFoundMessage>
          <NotFoundTitle>{t('title')}</NotFoundTitle>
          <NotFoundSubtitle>{t('subtitle')}</NotFoundSubtitle>
          <Link href="/" passHref>
            <NotFoundButton>{t('cta')}</NotFoundButton>
          </Link>
        </NotFoundMessage>
      </NotFoundContainer>
    </>
  );
};

export default NotFound;
